<template>
  <a
    :href="link"
    :target="isMobile ? '_self' : '_black'"
    class="text-decoration-none"
    @click="openLink(link)"
    v-if="link"
  >
    <slot>
      <v-btn
        color="primary"
        class="text-none"
        small
        rounded
        depressed
        v-bind="$attrs"
      >
        <v-icon :left="!icon"> mdi-directions </v-icon>
        <span v-if="!icon"> Dẫn đường </span>
      </v-btn>
    </slot>
  </a>
</template>

<script>
export default {
  props: {
    link: { required: true, type: String },
    icon: Boolean,
  },
  data: () => ({}),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    openLink(link) {
      window.open(link);
    },
  },
};
</script>

<style scoped></style>
