import { sdk } from "@/service/http/axios";

import { Organization } from "../model/Organization";
import { Place } from "../model/Place";
import storage from "../storage";

export const API_SEARCH = (params, pagination) => {
  if (pagination) {
    params.page = pagination.page;
    params.perPage = pagination.perpage;
  }
  params = { ...params };
  if (params.location) {
    params.location = `${params.location.lat},${params.location.lng}`;
  }
  if (params.state) {
    params.stateId = params.state.id;
    delete params.state;
  }
  if (params.district) {
    params.districtId = params.district.id;
    delete params.district;
  }
  if (params.commune) {
    params.subDistrictId = params.commune.id;
    delete params.commune;
  }
  if (params.bbox) {
    params.bbox = params.bbox.toString();
  }
  if (!params.isCheckMove) {
    delete params.bbox;
  }
  delete params.isCheckMove;
  return sdk.request({ url: "search-places", params }).then((res) => {
    const data_return = res.data.data.map((x) => new Place(x));

    if (pagination) {
      return {
        list: data_return,
        pagination: {
          page: res.data.meta.currentPage,
          perpage: res.data.meta.perPage,
          total: res.data.meta.total,
        },
      };
    }
    return {
      list: data_return,
      pagination: { page: 1, perpage: 20, total: data_return.length },
    };
  });
};
export const API_STATE = () =>
  sdk.request({ url: "states" }).then((res) =>
    res.data.data.map((x) => ({
      nameWithType: x.type ? `${x.type} ${x.name}` : x.name,
      ...x,
    }))
  );
export const API_DISTRICTS = (state_code) =>
  sdk.request({ url: `states/${state_code}/districts` }).then((res) =>
    res.data.data.map((x) => ({
      nameWithType: x.type ? `${x.type} ${x.name}` : x.name,
      ...x,
    }))
  );
export const API_COMMUNES = (state_code) =>
  sdk.request({ url: `districts/${state_code}/sub-districts` }).then((res) =>
    res.data.data.map((x) => ({
      nameWithType: x.type ? `${x.type} ${x.name}` : x.name,
      ...x,
    }))
  );
export const API_MAP = () =>
  sdk
    .request({ url: "map" })
    .then((res) => new Organization(res.data.data.organization));
export const API_LAYER = (params) => {
  if (params.zoom) {
    params.zoom = Math.round(params.zoom);
  }
  params.with = "images;contacts";
  return sdk.request({ url: "layers", params }).then((res) => {
    return res.data.data;
  });
};
export const API_PLACE = (params) =>
  sdk.request({ url: `place`, params }).then((res) => new Place(res.data.data));
export const API_GET_LOCATION = (lat, lng) =>
  sdk
    .request(
      { url: "adms-by-location", params: { lat, lng } },
      { withoutToken: true }
    )
    .then((res) => res.data.data);

export async function API_LOCATION() {
  let location = await storage.getItem("cache-location");
  if (!location) {
    location = await fetch(
      "https://api.ipdata.co/?api-key=bf329bf3d4ebf60c438b7054e7363edc10bcb4149e30d2c9bc390b75"
    ).then((response) => response.json());
  }
  storage.setItem("cache-location", location);
  if (location.latitude && location.longitude) {
    return {
      center: {
        lat: location.latitude,
        lng: location.longitude,
      },
    };
  }
  return {
    center: {
      lat: 20.8494022,
      lng: 105.85121154785156,
    },
  };
}
